import { Injectable, inject } from '@angular/core';
import { HttpClient, type HttpHeaders, HttpEventType, type HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface RequestOptions {
  headers?: Record<string, string>;
}

export interface UploadEvent {
  status: 'progress' | 'complete' | 'unhandled';
  loaded?: number;
  total?: number;
  error?: string;
  response?: any;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly http = inject(HttpClient);

  private get baseUrl(): string {
    return ConfigService.settings.apiUrl;
  }

  public async get(url: string, headers?: HttpHeaders, criteria?: HttpParams): Promise<any> {
    return await this.http
      .get(`${this.baseUrl}/${url}`, {
        headers,
        params: { ...criteria },
      })
      .toPromise();
  }

  public async patch(url: string, body: any, headers?: HttpHeaders): Promise<any> {
    return await this.http
      .patch(`${this.baseUrl}/${url}`, body, {
        headers,
      })
      .toPromise();
  }

  public patchV2<T = any>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}/${url}`, body, {
      headers,
    });
  }

  public async delete(url: string, headers?: HttpHeaders): Promise<any> {
    return await this.http
      .delete(`${this.baseUrl}/${url}`, {
        headers,
      })
      .toPromise();
  }

  public deleteV2(url: string, headers?: HttpHeaders): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${url}`, {
      headers,
    });
  }

  public async post(url: string, body: any, headers?: HttpHeaders): Promise<any> {
    return await this.http
      .post(`${this.baseUrl}/${url}`, body, {
        headers,
      })
      .toPromise();
  }

  public postV2<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${url}`, body, {
      headers,
    });
  }

  public upload(url: string, body: any, headers?: HttpHeaders): Observable<UploadEvent> {
    return this.http
      .post(`${this.baseUrl}/${url}`, body, {
        headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                status: 'progress',
                loaded: event.loaded,
                total: event.total,
              };
            case HttpEventType.Response:
              return { status: 'complete', response: event.body };
            default:
              return { status: 'unhandled' };
          }
        }),
      );
  }

  public async put(url: string, body?: any, headers?: HttpHeaders): Promise<any> {
    return await this.http
      .put(`${this.baseUrl}/${url}`, body, {
        headers,
      })
      .toPromise();
  }

  public putV2<T>(url: string, body?: any, headers?: HttpHeaders): Observable<any> {
    return this.http.put<T>(`${this.baseUrl}/${url}`, body, {
      headers,
    });
  }

  public getV2<T>(url: string, headers?: HttpHeaders, criteria?: HttpParams, type = 'json'): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${url}`, {
      headers,
      params: { ...criteria },
      responseType: type as any,
    });
  }
}
